import "bootstrap-icons/font/bootstrap-icons.css";
import opensea from "../assets/opensea.png";

const Footer = () => {
  return (
    <div className="container-fluid text-center footer">
      <div className="container hstack justify-content-center">
        <a
          href="https://discord.gg/c8FwpmV3JM"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="bi bi-discord"></i>
        </a>
        <a
          href="https://twitter.com/OnejungleOrg"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="bi bi-twitter"></i>
        </a>
        <a
          href="https://opensea.io/collection/miner-mike"
          target="_blank"
          rel="noopener noreferrer"
          style={{ paddingTop: 3 }}
        >
          <img
            src={opensea}
            alt="OpenSea"
            style={{
              width: 24,
            }}
          />
        </a>
      </div>
    </div>
  );
};

export default Footer;
