import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import store from "./redux/store";

import "./styles/styles.css";
import "./styles/styles.scss";
import "bootstrap-icons/font/bootstrap-icons.css";

import reportWebVitals from "./reportWebVitals";
import Minting from "./pages/minting.page";
import Footer from "./components/footer.component";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Minting />
      <Footer />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
